@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Inter:wght@200;300&family=Manrope:wght@200;300;400&family=Poppins:wght@600&display=swap");

body {
  margin: 0 auto;
  padding: 0;
  background-color: #bdb5d5;
  color: black;
  font-family: "DM Sans", sans-serif;
}

a {
  color: #754a92;
  text-decoration: none;
}

.App {
  margin: auto;
  text-align: center;
}

.portrait {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px 0 0 15px;
}

.content {
  margin: 250px auto 0px auto;
  display: flex;
  background-color: white;
  border-radius: 15px;
  width: 700px;
  box-shadow: rgb(149 157 165 / 40%) 0px 8px 50px;
}

.content-left {
  width: 40%;
}

.content-right {
  width: 60%;
  padding: 20px;
  text-align: left;
}

footer {
  font-size: 0.8rem;
  padding: 15px 0;
}

@media only screen and (max-width: 800px) {
  .content {
    margin: 25px auto 0px auto;
    width: 300px;
  }

  .content-left {
    width: 0%;
  }
  
  .content-right {
    width: 100%;
    padding: 15px;
    text-align: center;
  }
}